import "./App.css";
import Card from "./Components/card";
import ParticlesBg from "particles-bg";

function App() {
  return (
    <div className="App">
      <ParticlesBg color="black" type="cobweb" bg={true} />
      <Card />
    </div>
  );
}

export default App;
