import React from "react";
import "./card.css";
import Logo from "../assets/logo-linktree.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import GoogleIcon from "@mui/icons-material/Google";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function Card() {
  return (
    <div className="card">
      <div className="card_body">
        <div className="profile">
          <img src={Logo} alt="" className="avatar" />
          <h3 style={{ textAlign: "center" }}>
            PT. Arenvy Teknologi Marketing
          </h3>
          <p style={{ textAlign: "center" }}>Spesialis Digital Marketing</p>
        </div>
        <div className="button-wrap">
          <a
            href="https://www.instagram.com/arenvy.group/"
            target="_blank"
            className="button"
          >
            <InstagramIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Instagram</span>
          </a>
        </div>
        <div className="button-wrap">
          <a
            href="https://id.linkedin.com/company/pt-arenvy-teknologi-marketing?trk=public_profile_topcard-current-company"
            target="_blank"
            className="button"
          >
            <LinkedInIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Linked In</span>
          </a>
        </div>
        <div className="button-wrap">
          <a href="https://arenvy.com" target="_blank" className="button">
            <DevicesRoundedIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Jasa Pembuatan Website</span>
          </a>
        </div>
        <div className="button-wrap">
          <a href="https://ads.arenvy.com" target="_blank" className="button">
            <GoogleIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Jasa Google Ads</span>
          </a>
        </div>
        <div className="button-wrap">
          <a
            href="https://api.whatsapp.com/send/?phone=6285718495229&text=Halo%20Ibu%20Lestari,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..."
            target="_blank"
            className="button"
          >
            <SupportAgentIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Customer Service (Lestari)</span>
          </a>
        </div>
        <div className="button-wrap">
          <a
            href="https://api.whatsapp.com/send/?phone=6281317284592&text=Halo%20Pak%20Alex,%20saya%20ingin%20bertanya%20tentang%20layanan%20arenvy..."
            target="_blank"
            className="button"
          >
            <SupportAgentIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Customer Service (Alex)</span>
          </a>
        </div>
      </div>
    </div>
  );
}
